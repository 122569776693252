
import { computed, defineComponent, onMounted, onUnmounted, ref } from "vue";
import { IonApp } from "@ionic/vue";
import TopBar from "@/components/Layout/Top/TopBar.vue";
import { useStore } from "vuex";
import { ICrudOptions } from "@/interfaces/ICrudOptions";
import useHelper from "@/composable/useHelper";
import { useRoute } from "vue-router";
import emitter from "@/utils/EventBus.ts";
import OfflineMediaDownloadModal from "@/components/Modals/OfflineMediaDownloadModal.vue";
import useRequest from "@/composable/useRequest";
import UserService from "@/services/UserService";

export default defineComponent({
  name: "FullAccessApp",
  components: {
    OfflineMediaDownloadModal,
    TopBar,
    IonApp,
  },

  setup() {
    const store = useStore();
    const route = useRoute();

    const { isCurrentRouteNotTheoryAndPreliminaryExam } = useHelper();
    const { data, fetch, success } = useRequest<boolean>();

    const findAllStudentEducationWithTheory = async (options: ICrudOptions) => {
      await store.dispatch("student-education/withTheory/findAll", options);
    };

    const findAllBroadcasts = async (opt: ICrudOptions) => {
      await store.dispatch("broadcast/list/findAll", opt);
    };

    const findAllDocs = async (opt: ICrudOptions) => {
      await store.dispatch("student-document/findAll", opt);
    };

    const getNews = () => {
      findAllBroadcasts({
        resource: "/students/current/news",
        descriptionField: "",
      });
    };

    const currentRouteName = computed(() => route.name);

    const getDocs = () => {
      findAllDocs({
        resource: "student-documents/student/current",
        hideSuccessMessage: true,
        descriptionField: "",
      });
    };

    const getNewsAndDocs = () => {
      getNews();
      getDocs();
    };

    const offlineMediaModal = ref(null);

    const timer = ref<any>(null);
    const lastInteractionTime = ref(Date.now());
    const hasContractTimer = ref<any>(null);

    onMounted(() => {
      if (isCurrentRouteNotTheoryAndPreliminaryExam.value) {
        findAllStudentEducationWithTheory({
          resource: "/student-educations/student/current",
          descriptionField: "",
        });
      }

      if (currentRouteName.value != "News") {
        getNews();
      }

      if (currentRouteName.value != "Docs") {
        getDocs();
      }

      emitter.$on("page-refresh", () => {
        getNewsAndDocs();
      });

      emitter.$on("show-offline-medial-modal", () => {
        //@ts-ignore
        offlineMediaModal.value?.showOfflineMediaModal();
      });

      window.addEventListener("mousemove", onInteraction);
      onInteraction();

      hasContractTimer.value = setInterval(
        async () => {
          const user = UserService.getUser();

          if (user.contractAccepted == true) {
            await fetch({
              url: `/students/current/has-new-contract`,
            });

            if (data.value && success.value) {
              user.contractAccepted = false;
              await UserService.setUser(user);
              location.reload();
            }
          }
        },
        process.env.VUE_APP_HAS_CONTRACT_INTERVAL ? Number(process.env.VUE_APP_HAS_CONTRACT_INTERVAL) : 3600000
      );
    });

    onUnmounted(() => {
      window.removeEventListener("mousemove", onInteraction);
      clearInterval(timer.value);
      clearInterval(hasContractTimer.value);
    });

    function startTimer() {
      const timeInterval = process.env.VUE_APP_BROADCAST_INTERVAL ? Number(process.env.VUE_APP_BROADCAST_INTERVAL) : 600000;

      timer.value = setInterval(() => {
        const currentTime = Date.now();
        const elapsed = currentTime - lastInteractionTime.value;
        if (elapsed > timeInterval) {
          clearInterval(timer.value);
          timer.value = null;
        } else {
          getNewsAndDocs();
        }
      }, timeInterval);
    }

    function onInteraction() {
      lastInteractionTime.value = Date.now();
      if (!timer.value) {
        startTimer();
      }
    }

    return {
      offlineMediaModal,
    };
  },
});
