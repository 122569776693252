
import { Options, Prop, Vue } from "vue-property-decorator";
import UserService from "@/services/UserService";
import { IonMenuToggle, isPlatform, menuController } from "@ionic/vue";
import { PropType } from "vue";
import { IBroadcast } from "@/interfaces/IBroadcast";
import take from "lodash/take";
import Message from "@/components/Message.vue";

@Options({
  components: {
    Message,
    IonMenuToggle,
  },
})
export default class News extends Vue {
  public name = "News";

  get driveSchoolName(): string {
    return UserService.getUser().drivingSchoolName;
  }

  @Prop({ type: Boolean, default: () => false })
  public loading!: boolean;

  @Prop({ type: Array as PropType<Array<IBroadcast>>, default: () => [] })
  public broadcasts!: Array<IBroadcast>;

  public onRoute(): void {
    if (!isPlatform("desktop")) {
      menuController.close("rightMobileMenu");
    }
    this.$router.push("/news");
  }

  public get lastTwoNews(): Array<IBroadcast> {
    return take(this.broadcasts, 2);
  }
}
