
import { mixins } from "vue-class-component";
import ModalMixin from "@/mixins/ModalMixin";
import { Options } from "vue-property-decorator";
import { loadingController } from "@ionic/vue";
import useFile from "@/composable/useFile";
import axios, { $fileAxios } from "@/utils/axios";
import { IMedia } from "@/interfaces/IMedia";
import { MediaTypeEnum } from "@/enums/MediaTypeEnum";
import { AxiosError, AxiosResponse } from "axios";

@Options({})
export default class OfflineMediaDownloadModal extends mixins(ModalMixin) {
  public name = "OfflineMediaDownloadModal";

  public fileSystem = useFile();
  public loading: any = null;

  public isLoading = false;

  public showOfflineMediaModal() {
    this.showModal(null, "offlineMediaDownloadModal");
  }

  private closeOfflineMediaModal() {
    this.hideModal(null, "offlineMediaDownloadModal");
  }

  private async showLoading() {
    this.loading = await loadingController.create({
      message: "Downloading...",
    });

    this.loading.present();
  }

  private hideLoading() {
    this.loading?.dismiss();
  }

  private async mediaBuilder(
    url: string,
    questionId: number,
    officialNumber: string,
    extension: string,
    reducedSize = false,
    mediaPosition: string | null = null
  ) {
    const suffix = this.fileSystem.getSuffix(mediaPosition);
    const builtPath = this.fileSystem.pathBuilder(questionId, officialNumber, extension, reducedSize, suffix);
    return new Promise((resolve, reject) => {
      return $fileAxios
        .get(url, {
          responseType: "arraybuffer",
        })
        .then((response: AxiosResponse) => {
          this.fileSystem
            .writeFile(response.data, builtPath)
            .then(() => {
              resolve("success");
            })
            .catch((error: Error) => {
              reject(error.message);
            });
        })
        .catch((err: AxiosError) => {
          reject(err.message);
        });
    });
  }

  public buildURL(datum: IMedia) {
    const URL = `/theory-questions/${datum.id}/media`;

    switch (datum.mediaType) {
      case MediaTypeEnum.jpeg:
      case MediaTypeEnum.jpg:
      case MediaTypeEnum.png: {
        const filename = datum.reducedSizeThumbnailStartFileName;
        let extension;
        if (!filename) {
          extension = this.fileSystem.extensionByMediaType(datum.mediaType);
        } else {
          extension = this.fileSystem.pathToExtension(filename);
        }

        const reducedSize = Boolean(filename);

        return [
          {
            url: URL + `?reducedSize=${reducedSize}&thumbnail=`,
            extension,
            officialNumber: datum.officialNumber,
            id: datum.id,
            reducedSize,
            mediaPosition: "START",
          },
        ];
      }
      case MediaTypeEnum.mp4: {
        const mediaCollection = [null, "START", "END"];

        const collection = [];

        for (const media of mediaCollection) {
          let reducedSize: boolean;
          let extension: string;
          let suffix: string;
          let mediaPosition: string | null;

          if (media == "START") {
            const filename = datum.reducedSizeThumbnailStartFileName;
            if (!filename) continue;
            extension = this.fileSystem.pathToExtension(filename);
            suffix = "thumbnail=START";
            reducedSize = Boolean(filename);
            mediaPosition = "START";
          } else if (media == "END") {
            const filename = datum.reducedSizeThumbnailEndFileName;
            if (!filename) continue;
            extension = this.fileSystem.pathToExtension(filename);
            suffix = "thumbnail=END";
            reducedSize = Boolean(filename);
            mediaPosition = "END";
          } else {
            const filename = datum.reducedSizeFileName;
            extension = "mp4";
            suffix = "";
            reducedSize = Boolean(filename);
            mediaPosition = null;
          }

          collection.push({
            url: URL + `?reducedSize=${reducedSize}&${suffix}`,
            extension,
            officialNumber: datum.officialNumber,
            id: datum.id,
            reducedSize,
            mediaPosition: mediaPosition,
          });
        }

        return collection;
      }
      default:
        return [];
    }
  }

  public async dispatch(data: IMedia[]) {
    const urls = [];

    for (const datum of data) {
      urls.push(...this.buildURL(datum));
    }

    const promises = urls.map((p) => this.mediaBuilder(p.url, p.id, p.officialNumber, p.extension, p.reducedSize, p.mediaPosition));

    await Promise.allSettled(promises).then(() => {
      this.hideLoading();
      this.$toasted.success(this.$t("messages.media_downloaded").toString(), { duration: 3000 });
      this.closeOfflineMediaModal();
    });
  }

  private async okDownload() {
    if (this.isLoading) return;
    await this.showLoading();
    this.isLoading = true;

    try {
      await this.fileSystem.deleteDir();
    } catch (e) {
      console.log(e);
    }

    try {
      const response = await axios.get<IMedia[]>("/theory-questions/media/all");
      await this.dispatch(response.data);
      this.stopLoading();
    } catch (e) {
      this.hideLoading();
      this.$toasted.error(e?.message);
      this.stopLoading();
    }
  }

  public stopLoading() {
    this.isLoading = false;
  }
}
