
import { Options } from "vue-property-decorator";
import Avatar from "@/components/Avatars/Avatar.vue";
import { namespace } from "s-vuex-class";
import UserMixin from "@/mixins/UserMixin";
import { mixins } from "vue-class-component";

import {
  IonContent,
  IonHeader,
  IonItem,
  IonMenu,
  IonTitle,
  IonToolbar,
  IonMenuButton,
  IonRow,
  IonCol,
  IonButtons,
  IonMenuToggle,
  IonBadge,
  IonLabel,
} from "@ionic/vue";
import DriveBuzzLogo from "@/components/DriveBuzzLogo.vue";
import News from "@/components/Layout/NewsBar/News.vue";
import AvatarAndLogout from "@/components/Layout/Top/AvatarAndLogout.vue";
import StudentDocumentsStoreMixin from "@/mixins/StudentDocumentsStoreMixin";
import { Capacitor } from "@capacitor/core";
import emitter from "@/utils/EventBus.ts";

const AuthModule = namespace("auth");
const BroadcastModule = namespace("broadcast");

@Options({
  components: {
    AvatarAndLogout,
    Avatar,
    News,
    DriveBuzzLogo,
    IonContent,
    IonHeader,
    IonItem,
    IonMenu,
    IonTitle,
    IonToolbar,
    IonMenuButton,
    IonRow,
    IonCol,
    IonButtons,
    IonMenuToggle,
    IonBadge,
    IonLabel,
  },
})
export default class TopBar extends mixins(UserMixin, StudentDocumentsStoreMixin) {
  public name = "TopBar";

  @BroadcastModule.Getter("getUnseenCount")
  public getUnseenCount!: number;

  public get isNative() {
    return Capacitor.isNativePlatform();
  }

  public openDownloadMediaModal() {
    emitter.$emit("show-offline-medial-modal");
  }
}
